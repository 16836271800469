import './Detalhes.css'
import BarraLateral from '../../lib/componentes/BarraLateral';
import NavBar from '../../lib/componentes/NavBar';
import Abas from '../../lib/componentes/Abas';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState, useCallback } from 'react';
import { fetchWithAuth, fetchWithoutAuth } from '../../servicos/api';
import { FaRegFolder } from "react-icons/fa6";
import { BiCopy } from "react-icons/bi";
import ImagePreview from '../../lib/componentes/ImagePreview';
import { Link } from 'react-router-dom';
import formatDate from '../../utils/formatDate';
import formatValor from '../../utils/formatValor';
import {API_URL} from '../../servicos/api';

function Detalhes() {

    const { ad_id } = useParams();
    const [anuncio,setAnuncio] = useState({});

    const fetchAnuncio = useCallback(async () => {
        try {
            const response = await fetchWithAuth(`${API_URL}/ad_livre/${ad_id}`);
            setAnuncio(response);
            
        } catch (error) {
            console.error('Error fetching anuncio:', error);
        }
    }, [ad_id]);

    const fetchWhatsappLink = useCallback(async () => {
        try {
            const valor = formatValor(anuncio.announced_value);
            const response = await fetchWithoutAuth(`${API_URL}/ad_livre/${ad_id}/link`);
            const message = `${anuncio.title}\n*>>>>R$ ${valor}<<<<*\n\nClique no link para fazer oferta pelo Whatsapp:\n${response.whatsapp_link}
            \nOu faça sua proposta pelo site:\nhttp://autoslances.com.br/venda/${ad_id}`;
            navigator.clipboard.writeText(message); // Copy to clipboard
            alert('Link copied to clipboard');
        } catch (error) {
            console.error('Error fetching WhatsApp link:', error);
        }
    }, [ad_id, anuncio]);

    useEffect(() => {
        fetchAnuncio();
    }, [fetchAnuncio]);

    console.log('Anuncio',anuncio)

    const mediaUrls = anuncio && anuncio.media ? anuncio.media.map(item => item.media_url) : [];
    const fipe_value = formatValor(anuncio.fipe_value);
    const announced_value = formatValor(anuncio.announced_value);
    const date = formatDate(anuncio.initial_date);

    return( 
    
        <div className='tela' style={{overflowY:'hidden'}}>
            <NavBar/>
            <Abas/>
            <BarraLateral/>

            <div className='conteudo'>

                <div className='titulo-anuncio-detalhes'>Anúncio - [{anuncio.title}]</div>
                
                <div className='descricao-detalhes' style={{overflowY:'hidden'}}>

                    <div>
                        <div className='foto-container-detalhes'>
                            <img className='foto-detalhes' src={mediaUrls[0]} alt='capa' />
                            <div style={{display:'inline-flex',marginTop:'50px',marginBottom:'50px'}}>
                                <div style={{marginRight:'10px'}}><FaRegFolder className='icone-detalhes'/></div>
                                <span className='texto-icone-detalhes'>Fotos e videos do carro</span>
                            </div>
                            <div className='container-imagepreview-detalhes'>
                                <ImagePreview mediaUrls={mediaUrls} url_type={'link'} />
                            </div>
                        </div>
                    </div>

                    <div></div>

                    <div className='tracejado'>

                        <span className='texto-detalhes-titulo'># PRONTA ENTREGA #</span>
                        <span className='texto-detalhes-titulo'>FIPE: R$ {fipe_value}</span>
                        <span className='texto-detalhes-titulo'>POR: R$ {announced_value}</span>
                        <p className='texto-detalhes'> 
                            {anuncio.details}
                        </p>

                    </div>

                    <div></div>

                    <div className='flex-detalhes' style={{marginRight:'5%'}}>
                        <div className='tracejado' style={{height:'30%',textAlign:'center',justifyContent:'center'}}>
                            <span className='texto-detalhes-botao' style={{marginBottom:'5%'}}>Anúncio criado em: {date}</span>
                            <span className='texto-detalhes-botao' style={{marginBottom:'2%'}}>Carro recebido do:</span>
                            <span className='texto-detalhes-titulo'>{anuncio.source}</span>
                        </div>
                        <button className='botao-detalhes' onClick={fetchWhatsappLink}><BiCopy/>Copiar Link do Anuncio</button>
                        <button className='botao-detalhes' style={{marginTop:'5%',fontSize:'100%'}}><Link to={`/meus-anuncios/detalhes/respostas-anuncio/${ad_id}`} style={{textDecoration:'none',color:'white'}}>Ver Ofertas dos Compradores</Link></button>
                        <button className='botao-remover-detalhes'>Remover do Estoque</button>
                    </div>


                </div>

                
            </div>

        </div>

    );
}

export default Detalhes;