const formatValor = (value) => {

    const numValue = parseFloat(value);

    let format = numValue.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    return format;

}


export default formatValor