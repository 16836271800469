import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';
import Cadastro from '../../../paginas/Cadastro';
import Entrar from '../../../paginas/Entrar'
import { AuthContext } from '../../../servicos/AuthContext';
import NavBarLogin from '../NavBarLogin';

const Navbar = () => {
    const { auth } = useContext(AuthContext);
    const [isCadastroOpen, setIsCadastroOpen] = useState(false);
    const [isEntrarOpen, setIsEntrarOpen] = useState(false);

    const openCadastro = () => {
        setIsCadastroOpen(true);
    };

    const closeCadastro = () => {
        setIsCadastroOpen(false);
    };

    const openEntrar = () => {
        setIsEntrarOpen(true);
    };

    const closeEntrar = () => {
        setIsEntrarOpen(false);
    };

    if (auth.isAuthenticated) {
        return <NavBarLogin />;
    }

    return (
        <div className="navbar">
            <div></div>
            <div>
                <div className='container-logo'>
                    <img className='imagem' src='logoauto.png' alt='logo' />
                    <Link to='/meus-anuncios' style={{ textDecoration: 'none', color: 'inherit' }}>
                        <div className='texto-logo'>AUTOSLANCES</div>
                    </Link>
                </div>
            </div>
            <div></div>
            <div className='botoes'>
                <div>Vantagens</div>
                <div>Planos</div>
                <div onClick={openEntrar} style={{ cursor: 'pointer' }}>Entrar</div>
                <div onClick={openCadastro} style={{ cursor: 'pointer' }}>Cadastrar</div>
            </div>
            <Cadastro isOpen={isCadastroOpen} onClose={closeCadastro} />
            <Entrar isOpen={isEntrarOpen} onClose={closeEntrar} />
        </div>
    );
};

export default Navbar;
