import './BotaoAzul.css'

function BotaoAzul({texto,estilo}) {

    return(

        <div className='botao-azul' style={estilo}>{texto}</div>
    );
}

export default BotaoAzul
