import './RespostasAnuncio.css';
import '../../App.css';
import NavBarLogin from '../../lib/componentes/NavBarLogin';
import BarraLateral from '../../lib/componentes/BarraLateral';
import React, { useEffect, useState, useCallback } from 'react';
import { fetchWithAuth, API_URL } from '../../servicos/api';
import Abas from '../../lib/componentes/Abas';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { addSale } from '../../servicos/api';
import formatValor from '../../utils/formatValor';
import formatDate from '../../utils/formatDate';
import formatTel from '../../utils/formatTel';


const RespostasAnuncio = () => {

    const { ad_id } = useParams();
    const [bids, setBids] = useState([]);
    const [anuncio,setAnuncio] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;

    const userId = localStorage.getItem('id');
            if (!userId) {
                throw new Error('No user ID found');
            }

    const handleSale = async (bid) => {
        try {

            const saleData = {

                ad_id:`${ad_id}`,
                initial_date: '2024-05-14',
                final_date: formatDate(bid.timestamp),
                bids: anuncio.bidsCount,
                status: 'vendido',
                seller_id: `${userId}`,
                sale_price: bid.bid_amount
        
            };
            
            await addSale(saleData);
            alert('Venda registrada com sucesso!');
        } catch (error) {
            console.log(bid)
            console.log(anuncio)
            console.error('Erro ao registrar venda:', error);
            alert('Erro ao registrar venda.');
        }
    };

    const fetchBids = useCallback(async () => {
        try {
            const response = await fetchWithAuth(`${API_URL}/bids/details/${ad_id}`);
            setBids(response.bids);
        } catch (error) {
            console.error('Error fetching bids:', error);
        }
    }, [ad_id]);

    const fetchAnuncio = useCallback(async () => {
        try {
            const anuncio = await fetchWithAuth(`${API_URL}/ad_livre/${ad_id}`);
            const bidsResponse = await fetchWithAuth(`${API_URL}/bids/count/${ad_id}`);
            anuncio.bidsCount = bidsResponse.count;
            setAnuncio(anuncio);
        } catch (error) {
            console.error('Error fetching anuncio:', error);
        }
    }, [ad_id]);

    useEffect(() => {
        fetchBids();
        fetchAnuncio();
    }, [fetchBids, fetchAnuncio]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = bids.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(bids.length / itemsPerPage);

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const mediaUrls = anuncio && anuncio.media ? anuncio.media.map(item => item.media_url) : [];
    const announced_value = formatValor(anuncio.announced_value);

    return (
        <div className="tela">
            <NavBarLogin />
            <Abas />
            <BarraLateral />
            <div className="conteudo">
                <h1 className="titulo-meusanuncios">Respostas ao anúncio</h1>

                <div style={{display:'flex',minHeight:'20%'}}>
                    <div className='anuncio-respostasAnuncios'>
                        <img src={mediaUrls[0]} alt='missing' style={{height:'auto',width:'200px',borderRadius:'5px'}}></img>
                        <div style={{fontSize:'140%',textAlign:'left',fontWeight:'bold'}}>{anuncio.title}<br></br>R$ {announced_value} <br></br>Local: Rio de Janeiro - RJ</div>
                        <div></div>
                        <button className="botao-ver-anuncio-bids">
                            <Link to={`/meus-anuncios/detalhes/${ad_id}`} style={{textDecoration:'none',color:'white'}}>
                                Ver Anúncio
                            </Link>
                        </button>
                    </div>
                </div>
                <table className="tabela-anuncios">
                    <thead>
                        <tr>
                            <th>NOME</th>
                            <th>Telefone</th>
                            <th>Oferta</th>
                            <th>VENDER</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((bid, index) => {
                            const oferta = formatValor(bid.bid_amount);
                            const telefone = formatTel(bid.buyer_phone);

                            return (
                            <tr key={index}>
                                <td>{bid.buyer_name}</td>
                                <td>{telefone}</td>
                                <td>R$ {oferta}</td>
                                <td><button className='botao-ver-anuncio-bids' onClick={() => handleSale(bid)}>Vender</button></td>
                            </tr>
                        )})}
                    </tbody>
                </table>
                {totalPages > 1 && (
                    <div className="pagination">
                        <button onClick={handlePrevPage} disabled={currentPage === 1}>
                            Anterior
                        </button>
                        <span>{currentPage} de {totalPages}</span>
                        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                            Próxima
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default RespostasAnuncio;
