import React, { useState, useEffect } from 'react';
import './BotaoToggle.css'
import { fetchWithAuth } from '../../../servicos/api';

const BottaoToggle = ({ status,ad_id }) => {
    const [isActive, setIsActive] = useState(false);

    // Atualiza o estado inicial com base na prop status
    useEffect(() => {
        setIsActive(status === 'ativo');
    }, [status]);

    const handleToggle = async () => {
        const newStatus = !isActive;
        const dataStatus = newStatus ? "ativo":"removido"
        const data = {"status": dataStatus}

        setIsActive(newStatus);

        // Aqui você pode adicionar a lógica para atualizar o status no banco de dados
        await fetchWithAuth(`http://127.0.0.1:5000/ad_livre/${ad_id}/oculto`,{

            method: `POST`,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)

        })
    };

    return (
        <label className="switch">
            <input 
                type="checkbox" 
                checked={isActive} 
                onChange={handleToggle} 
            />
            <span className="slider"></span>
        </label>
    );
};

export default BottaoToggle;
