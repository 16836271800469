import './Entrar.css';
import React, { useState, useContext } from 'react';
import Modal from 'react-modal';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { login } from '../../servicos/api';
import { AuthContext } from '../../servicos/AuthContext';

Modal.setAppElement('#root');

const Entrar = ({ isOpen, onClose }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const { login: authLogin } = useContext(AuthContext);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await login(formData);
            authLogin(response.access_token);
            onClose();
        } catch (error) {
            alert('Erro ao realizar login');
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Entrar"
            className="Modal"
            overlayClassName="Overlay"
        >
            <div className="banner-login">
                <h2 className="banner-title">Acesse sua Conta</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label className="form-label">Email:</label>
                        <input type="email" name="email" value={formData.email} onChange={handleChange} required className="form-input" />
                    </div>
                    <div className="form-group">
                        <label className="form-label">Senha:</label>
                        <div className="password-input-container">
                            <input type={showPassword ? "text" : "password"} name="password" value={formData.password} onChange={handleChange} required className="form-input password-input" />
                            <span className="toggle-password" onClick={toggleShowPassword}>
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                        </div>
                    </div>
                    <button type="submit" className="login-button">Entrar</button>
                </form>
            </div>
        </Modal>
    );
};

export default Entrar;
