const formatTel = (telefone) => {

    //21999996122

    const ddd = telefone.slice(0,2);
    const primeiraParte = telefone.slice(2,7);
    const segundaParte = telefone.slice(7,11);

    return `(${ddd}) ${primeiraParte}-${segundaParte}`


}

export default formatTel