import NavBar from "../../lib/componentes/NavBar";
import Footer from "../../lib/componentes/Footer";
import "../../App.css";
import "./PaginaInicial.css";

function PaginaInicial() {

    return(

        <div className="tela">
            <NavBar/>

            <div className="banner">
                <div style={{width:'10%'}}></div>
                LOREM IPSUM DOLOR
            </div>

            <div className="textoPrincipal">

                <div className="descricao">
                    <div className="descricaoPrincipal">O melhor assistente de revendas e repasses automotivos do Brasil.</div>
                    <div className="descricaoSecundaria">Autos Lances é seu Co-piloto para divulgar seus carros e gerenciar respostas no WhatsApp de forma automática, poupando horas de interação manual e aumentando suas chances de venda em um curto espaço de tempo.</div>
                </div>

                <div className="dados">
                    <div style={{width:'5%'}}></div>
                    <img src="dados.png" className="img" alt="imagem"></img>
                </div>
            </div>

            <div className="container">

                <div className="features">

                    <div className="func">

                        <img className="imagemFeaturesPagIni" src="placeholder.png" alt="imagem"></img>
                        <div className="titulo">LOREM IPSUM DOLOR</div>
                        <div className="texto">LOREM IPSUM DOLOR</div>
                        
                    </div>

                    <div className="func">

                        <img className="imagemFeaturesPagIni" src="placeholder.png" alt="imagem"></img>
                        <div className="titulo">LOREM IPSUM DOLOR</div>
                        <div className="texto">LOREM IPSUM DOLOR</div>

                    </div>
                    
                    <div className="func">

                        <img className="imagemFeaturesPagIni" src="placeholder.png" alt="imagem"></img>
                        <div className="titulo">LOREM IPSUM DOLOR</div>
                        <div className="texto">LOREM IPSUM DOLOR</div>

                    </div>

                </div>

            </div>

            <Footer/>
        </div>

    );
}

export default PaginaInicial;