import { IoRadioButtonOff,IoRadioButtonOn } from "react-icons/io5";
import React, { useState } from 'react';
import './RadioButton.css';


function RadioButton({options,initialValue}){

    const [selectedValue, setSelectedValue] = useState(initialValue);

    const handleRadioChange = (value) => {
        setSelectedValue(value);
    };


    return(

        <div className="radio-group">
      {options.map((option) => (
        <label key={option.value} className="radio-container">
          <input
            type="radio"
            value={option.value}
            checked={selectedValue === option.value}
            onChange={() => handleRadioChange(option.value)}
          />
          {selectedValue === option.value ? (
            <IoRadioButtonOn className="radio-iconON" />
          ) : (
            <IoRadioButtonOff className="radio-iconOFF" />
          )}
          <span className="radio-label">{option.label}</span>
        </label>
        ))}
        </div>
    );
}

export default RadioButton;