import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import './../NavBar';
import { AuthContext } from '../../../servicos/AuthContext';

const NavBarLogin = () => {
    const { logout } = useContext(AuthContext);

    const handleLogout = () => {
        logout();
        window.location.href = '/';
    };

    return (
        <div className="navbar">
            <div></div>
            <div>
            <div className='container-logo'>
                    <img className='imagem' src='/logoauto.png' alt='logo' />
                    <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                        <div className='texto-logo'>AUTOSLANCES</div>
                    </Link>
                </div>
            </div>
            <div></div>
            <div className='botoes'>
            <Link to='/meus-anuncios' style={{ textDecoration: 'none', color: 'inherit' }}>
                <div>Meus Anúncios</div>
                </Link>
                <div>Vantagens</div>
                <div>Planos</div>
                <div onClick={handleLogout} style={{ cursor: 'pointer' }}>Logout</div>
            </div>
        </div>
    );
};

export default NavBarLogin;
