import './Venda.css'
import NavBarPub from '../../lib/componentes/NavBarPub';
import { fetchWithAuth,API_URL} from '../../servicos/api';
import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { addBid } from '../../servicos/api';
import formatValor from '../../utils/formatValor';
import { fetchWithoutAuth } from '../../servicos/api';
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

function Venda() {

    const { ad_id } = useParams();
    const [anuncio,setAnuncio] = useState({});
    const seller_id = localStorage.getItem('id');
    const [pageInfo, setPageInfo] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    const [bidData, setBidData] = useState({

        ad_id:`${ad_id}`,
        buyer_name: '',
        buyer_phone: '',
        bid_amount: '',
        seller_phone: '',
        ad_title: ''

    });

    useEffect(() => {
        if (anuncio.title && pageInfo['page_phone']) {
            setBidData(prevData => ({
                ...prevData,
                ad_title: anuncio.title,
                seller_phone: pageInfo['page_phone']
            }));
        }
    }, [anuncio, pageInfo]);

    const fetchPageInfo = useCallback(async () => {
        try {
            const response = await fetchWithoutAuth(`${API_URL}/sellers/${seller_id}/page`);
            setPageInfo(response);


        } catch (error) {
            console.error('Error fetching page info:', error);
        }
    }, [seller_id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBidData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await addBid(bidData);
            alert('Bid adicionada!');
        } catch (error) {
            console.log(bidData)
            alert('Erro!');
        }
    };

    const fetchAnuncio = useCallback(async () => {
        try {
            const response = await fetchWithAuth(`${API_URL}/ad_livre/${ad_id}`);
            setAnuncio(response);
            
        } catch (error) {
            console.error('Error fetching anuncio:', error);
        }
    }, [ad_id]);

    useEffect(() => {
        fetchAnuncio();fetchPageInfo();
    }, [fetchAnuncio,fetchPageInfo]);


    const mediaUrls = anuncio && anuncio.media ? anuncio.media.map(item => item.media_url) : [];
    const totalImages = mediaUrls.length;
    const imagesPerPage = 3;

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => 
            Math.max(prevIndex - imagesPerPage, 0)
        );
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            Math.min(prevIndex + imagesPerPage, totalImages - imagesPerPage)
        );
    };

    const ImagensVisiveis = mediaUrls.slice(currentIndex, currentIndex + imagesPerPage);

    const fipe_value = formatValor(anuncio.fipe_value);
    const announced_value = formatValor(anuncio.announced_value);

 

    return (
        <div className="tela">
            <NavBarPub seller_id={seller_id}/>

            <div className="conteudo-venda">

                <div className='vitrine-venda'>

                    {totalImages > imagesPerPage && (
                        <button onClick={handlePrev} className='botao-vitrine-venda'>
                            <IoIosArrowBack className='seta-foto-venda'/>
                        </button>
                    )}

                    {ImagensVisiveis.map((url, index) => (
                        <img key={index} className='foto-venda' src={url} alt={`foto-${index}`} />
                    ))}

                    {totalImages > imagesPerPage && (
                        <button onClick={handleNext} className='botao-vitrine-venda'>
                            <IoIosArrowForward className='seta-foto-venda'/>
                        </button>
                    )}

                </div>

                <div className='titulo-anuncio-venda'>{anuncio.title}</div>

                <div className='grid-detalhes-venda'>

                    <div className='tracejado-venda'>
                        <p className='texto-detalhes-venda'>
                            {anuncio.details}
                        </p>
                    </div>

                    <form className='proposta-venda' onSubmit={handleSubmit}>
                        <div className='texto-valor-venda'>FIPE: R$ {fipe_value}</div>
                        <div className='texto-valor-venda' style={{fontWeight:'bolder',fontSize:'160%'}}>POR: R$ {announced_value}</div>

                        <p style={{fontFamily:'Inter',fontSize:'120%'}}>Faça uma proposta ao vendedor</p>

                        <div className="campo-proposta-venda">
                            <p className='texto-input-venda'>Seu Nome</p>
                            <input type="text" name="buyer_name" value={bidData.buyer_name} onChange={handleChange} placeholder="Nome Completo" className="input-campo-venda" />
                        </div>

                        <div className="campo-proposta-venda">
                            <p className='texto-input-venda'>Celular</p>
                            <input type="text" name='buyer_phone' value={bidData.buyer_phone} onChange={handleChange} placeholder="( _ _ ) _ _ _ _ _ - _ _ _ _" className="input-campo-venda" />
                        </div>

                        <div className="campo-proposta-venda">
                            <p className='texto-input-venda'>Valor da Proposta</p>
                            <input type="text" name='bid_amount' value={bidData.bid_amount} onChange={handleChange} placeholder="Por quanto quer fechar a compra?" className="input-campo-venda" />
                        </div>

                        <button type='submit' className="botao-proposta-venda">
                            Fazer Proposta
                        </button>

                    </form>
                </div>

            </div>
                
        </div>
    );
}

export default Venda;