import './FormMeuEstoque.css'
import { LuUploadCloud } from "react-icons/lu"
import React, { useEffect, useState, useCallback } from 'react';
import { fetchWithAuth } from '../../servicos/api';
import { addData } from '../../servicos/api';
import {API_URL} from '../../servicos/api';

function FormMeuEstoque() {

    const [edit,setEdit] = useState(false);
    const [pageInfo, setPageInfo] = useState({
        page_phone: '',
        page_picture: '',
        page_title: ''
    });
    const [sellerId,setSellerId] = useState(0);
    const [imageKey, setImageKey] = useState(Date.now());
    const [newImage, setNewImage] = useState(null);

    const toggleEdit = () => {

        setEdit(!edit);
    };

    const fetchPageInfo = useCallback(async () => {
        try {
            const userId = localStorage.getItem('id');
            if (!userId) {
                throw new Error('No user ID found');
            }
            setSellerId(userId);
            const response = await fetchWithAuth(`${API_URL}/sellers/${userId}/page`);
            setPageInfo({
                page_phone: response.page_phone || '',
                page_picture: response.page_picture || '',
                page_title: response.page_title || ''
            });

            if (!response.page_phone && !response.page_picture && !response.page_title) {
                setEdit(true);
            }

        } catch (error) {
            console.error('Error fetching page info:', error);
        }
    }, []);

    useEffect(() => {
        fetchPageInfo();
    }, [fetchPageInfo,edit]);

    console.log(pageInfo)

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPageInfo((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0]; // Captura todos os arquivos selecionados pelo usuário
        
        const reader = new FileReader();

        reader.onloadend = () => {
            const base64String = reader.result;
            const regex = /^data:image\/[a-zA-Z+]+;base64,/;
            const base64WithoutPrefix = base64String.replace(regex, "");
            const media = base64WithoutPrefix

            // Quando todas as imagens forem processadas, atualiza o estado

            setNewImage(media);
            setImageKey(Date.now());
            alert('Imagem Adicionada!');
        };

        if (file) {
            reader.readAsDataURL(file); // Lê o arquivo como base64
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (newImage != null) {
                console.log(newImage);
                const updatedPageInfo = {
                    ...pageInfo,
                    page_picture: newImage,
                };

                await addData(updatedPageInfo,`sellers/${sellerId}/page`);
                setNewImage(null);
                toggleEdit();
                alert('Página Adicionada!');
                console.log(pageInfo);
            }

            else{

                await addData(pageInfo,`sellers/${sellerId}/page/noImage`);
                toggleEdit();
                alert('Página Adicionada!222');
            }
        } catch (error) {
            alert('Erro!');
        }
    };

    if (edit) {

        return(

            <form className='dados-meuEstoque' onSubmit={handleSubmit}>
                        <div className='campos-meuEstoque' style={{width:'20%'}}>

                            <p className='texto-dados-meuEstoque'>Sua Logo</p>
                            <div className='inserirFoto-meuEstoque' style={{borderRadius:'10px', borderStyle:'dashed'}}>

                                <LuUploadCloud/>
                                <div>Drop files here</div>
                                <div>Supported formats: PNG, JPG</div>
                                <div>OR</div>
                                <input 
                                    type="file" 
                                    name="page_picture"
                                    onChange={handleImageChange}
                                    id = "file"
                                    className="inputfile"/>
                                    <label htmlFor="file">Browse Files</label>

                            </div>

                        </div>

                        <div className='campos-meuEstoque' style={{marginLeft:'40px',width:'25%'}}>
                            
                            <div className='input-dados-meuEstoque'>
                                <label className='texto-dados-meuEstoque'>Título da Sua Página</label>
                                <input 
                                type="text" 
                                onChange={handleChange}
                                name="page_title"
                                value={pageInfo['page_title']}
                                required 
                                className="caixaInput-dados-meuEstoque" 
                                placeholder='Nome da Página' />
                            </div>

                            <div className='input-dados-meuEstoque'>
                                <label className='texto-dados-meuEstoque'>Telefone para Contato</label>
                                <input 
                                type="text" 
                                onChange={handleChange}
                                name="page_phone"
                                value={pageInfo['page_phone']}
                                required 
                                className="caixaInput-dados-meuEstoque" 
                                placeholder='( _ _ ) _ _ _ _ _ - _ _ _ _' />
                            </div>                 

                        </div>

                        <button type='submit' className='botao-dados-meuEstoque' style={{marginLeft:'50px'}}>Salvar Dados</button>
                        <button className='botao-dados-meuEstoque' onClick={toggleEdit} style={{backgroundColor:'red',marginLeft:'50px'}}>Cancelar</button>
                        

                    </form>
        )
    } else {

        return(            

            <div className='dados-meuEstoque' onSubmit={handleSubmit}>
                <div className='campos-meuEstoque' style={{width:'10%'}}>

                    <p className='texto-dados-meuEstoque'>Sua Logo</p>
                    <img className='logo-formMeuEstoque' src={pageInfo.page_picture} key={imageKey} alt='capa' />

                </div>

                <div className='campos-meuEstoque' style={{marginLeft:'20px',width:'15%'}}>
                    
                    <div className='input-dados-meuEstoque'>
                        <p className='texto-dados-meuEstoque'>Título da Sua Página</p>
                        <p className='texto-dados-meuEstoque'>{pageInfo.page_title}</p>
                        
                    </div>

                    <div className='input-dados-meuEstoque'>
                        <p className='texto-dados-meuEstoque'>Telefone para Contato</p>
                        <p className='texto-dados-meuEstoque'>{pageInfo.page_phone}</p>

                    </div>                 

                </div>

                <button className='botao-dados-meuEstoque' onClick={toggleEdit}>Edit</button>


            </div>
        )
    }
}

export default FormMeuEstoque;