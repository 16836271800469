import './Footer.css'
import { Link } from 'react-router-dom';

function Footer(){

    return (

        <div className='container-footer'>

            <div className='footer'>

                <div className='coluna'>

                    <img src='/logofooter.png' alt='logo'></img>
                    <div className='texto'>A sua plataforma de revenda e repasse</div>

                </div>

                <div className='coluna'>

                    <div className='titulo'>Produto</div>
                    <div className='texto'>Features</div>
                    <div className='texto'>Preço</div>

                </div>

                <div className='coluna'>
                    
                    <div className='titulo'>Recursos</div>
                    <div className='texto'>Blog</div>
                    <Link to='/politica-de-privacidade'><div className='texto'>Politica de Privacidade</div></Link>

                </div>

                <div className='coluna'>

                    <div className='titulo'>Empresa</div>
                    <div className='texto'>Sobre nós</div>
                    <div className='texto'>Junte-se a nós</div>

                </div>

                <div className='coluna'>

                    <div className='titulo'>Suporte</div>
                    <div className='texto'>Central de Ajuda</div>
                    <div className='texto'>Supporte de Chat</div>

                </div>

            </div>

        </div>
    );
}

export default Footer;