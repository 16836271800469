import './Cadastro.css';
import React, { useState } from 'react';
import Modal from 'react-modal';
import InputMask from 'react-input-mask';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { register } from '../../servicos/api';

Modal.setAppElement('#root');

const Cadastro = ({ isOpen, onClose }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        password: '',
        plan_type: 1, // Valor padrão, ajuste conforme necessário
        login_method: 'email', // Valor padrão, ajuste conforme necessário
        profile_picture: 'default'

    });

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await register(formData);
            alert('Cadastro realizado com sucesso!');
            onClose();
        } catch (error) {
            alert('Erro ao realizar cadastro');
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Cadastro"
            className="Modal"
            overlayClassName="Overlay"
        >
            <div className="banner-cadastro">
                <h2 className="banner-title">Crie uma Conta</h2>
                <p className="banner-description">O assistente de anúncios mais completo para a revenda automotiva do Brasil.</p>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label className="form-label">Nome Completo:</label>
                        <input type="text" name="name" value={formData.name} onChange={handleChange} required className="form-input" />
                    </div>
                    <div className="form-group">
                        <label className="form-label">Celular:</label>
                        <InputMask mask="(99) 99999-9999" name="phone" value={formData.phone} onChange={handleChange} required className="form-input" />
                    </div>
                    <div className="form-group">
                        <label className="form-label">Email:</label>
                        <input type="email" name="email" value={formData.email} onChange={handleChange} required className="form-input" />
                    </div>
                    <div className="form-group">
                        <label className="form-label">Senha:</label>
                        <div className="password-input-container">
                            <input type={showPassword ? "text" : "password"} name="password" value={formData.password} onChange={handleChange} required className="form-input password-input" />
                            <span className="toggle-password" onClick={toggleShowPassword}>
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                        </div>
                    </div>
                    <button type="submit" className="register-button">Registrar</button>
                </form>
            </div>
        </Modal>
    );
};

export default Cadastro;
