const formatDate = (stringData) => {

    const valorData = new Date(stringData);
    const day = String(valorData.getDate() + 1).padStart(2, '0');
    const month = String(valorData.getMonth() + 1).padStart(2, '0');
    const year = valorData.getFullYear();

    return `${day}/${month}/${year}`;

}


export default formatDate