import './ImagePreview.css'

function ImagePreview({mediaUrls,url_type}){

    const filledMediaUrls = [...mediaUrls, ...Array(6 - mediaUrls.length).fill(null)];


    return(

        <div className='image-preview'>

            {filledMediaUrls.map((url, index) => {

                let borda = '';
                if (index === 0) {
                    borda = '-borda-superior-esquerda';
                } else if (index === 2) {
                    borda = '-borda-superior-direita';
                } else if (index === 3) {
                    borda = '-borda-inferior-esquerda';
                } else if (index === 5) {
                    borda = '-borda-inferior-direita';
                }

                const imageUrl = url ? (url_type === 'base64' ? `data:image/png;base64,${url}` : url) : '/placeholder-image.jpg';

                return (
                    <img key={index} src={imageUrl} alt={'Imagem não disponível'} className={`preview-image${borda}`} />
                )
            })}

        </div>
    );
}

export default ImagePreview;