export const API_URL = 'https://autoslances.com.br/api'; // Substitua pelo URL correto da sua API

export const register = async (userData) => {
    try {
        const response = await fetch(`${API_URL}/auth/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        });

        if (!response.ok) {
            throw new Error('Failed to register');
        }

        return await response.json();
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const login = async (credentials) => {
    try {
        const response = await fetch(`${API_URL}/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(credentials),
        });

        if (!response.ok) {
            throw new Error('Failed to login');
        }

        const data = await response.json();
        localStorage.setItem('token', data.access_token);
        localStorage.setItem('id', data.user_id); // Salva o token no localStorage
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const fetchWithAuth = async (url, options = {}) => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('No token found');
    }

    const response = await fetch(url, {
        ...options,
        headers: {
            ...options.headers,
            'Authorization': `Bearer ${token}`,
        },
    });

    if (!response.ok) {
        console.error('Fetch failed:', response.status, response.statusText); // Log do erro
        throw new Error('Failed to fetch');
    }

    return await response.json();
};

export const fetchWithoutAuth = async (url, options = {}) => {
    const response = await fetch(url, options);

    if (!response.ok) {
        console.error('Fetch failed:', response.status, response.statusText); // Log do erro
        throw new Error('Failed to fetch');
    }

    return await response.json();
};

//substituir todas as funcoes add abaixo por addData pois ela é genérica e as outras desnecessarias
export const addData = async (Data,endpoint) => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('No token found');
    }
    try {
        const response = await fetch(`${API_URL}/${endpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(Data),
        });

        if (!response.ok) {
            throw new Error('Failed to add data');
        }

        return await response.json();
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};


export const addTable = async (tableData) => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('No token found');
    }
    try {
        const response = await fetch(`${API_URL}/ad_livre`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(tableData),
        });

        if (!response.ok) {
            throw new Error('Failed to add table');
        }

        return await response.json();
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const addBid = async (bidData) => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('No token found');
    }
    try {
        const response = await fetch(`${API_URL}/bids`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(bidData),
        });

        if (!response.ok) {
            throw new Error('Failed to add table');
        }

        return await response.json();
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const addSale = async (saleData) => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('No token found');
    }
    try {
        const response = await fetch(`${API_URL}/sales`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(saleData),
        });

        if (!response.ok) {
            throw new Error('Failed to add table');
        }

        return await response.json();
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};