const dicionarioAbas = {

    'meus-anuncios':'Anúncios',
    'criar-anuncio':'Criar Novo',
    'detalhes' : 'Detalhes',
    'encerrados' : 'Encerrados',
    'respostas-anuncio':'Respostas',
    'meu-estoque':'Meu Estoque'
};

export default dicionarioAbas