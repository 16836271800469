import './NavBarPub.css'
import { FaWhatsapp } from "react-icons/fa";
import React, { useEffect, useState, useCallback } from 'react';
import { fetchWithoutAuth,API_URL } from '../../../servicos/api';

function NavBarPub({seller_id}) {

    const [pageInfo, setPageInfo] = useState([]);

    const fetchPageInfo = useCallback(async () => {
        try {
            const response = await fetchWithoutAuth(`${API_URL}/sellers/${seller_id}/page`);
            setPageInfo(response);


        } catch (error) {
            console.error('Error fetching page info:', error);
        }
    }, [seller_id]);

    useEffect(() => {
        fetchPageInfo();
    }, [fetchPageInfo]);

    return (
        <div className="navbar-pub">
            <div>
                <div className='container-logo-pub'>
                    <img className='imagem-pub' src={pageInfo['page_picture']} alt='logo' />
                    <div className='texto-logo-pub'>{pageInfo['page_title']}</div>
                </div>
            </div>
            <div className='contato-pub'>
                <FaWhatsapp style={{fontSize:'180%'}}/>
                <div>{pageInfo['page_phone']}</div>
            </div>
        </div>
    );


}

export default NavBarPub;

