import './Abas.css'
import { Link, useLocation } from 'react-router-dom'
import dicionarioAbas from './dicio';
import { IoIosArrowForward } from "react-icons/io";

function Abas() {

    const location = useLocation();
    const pathnames = location.pathname.split('/').filter(x => x);

    const lastIndex = pathnames.length - 1;
    if (lastIndex >= 0 && /^\d+$/.test(pathnames[lastIndex])) {
        pathnames.pop(); // Remove the last segment (ad_id)
    }
    

    const renderBreadcrumbItems = () => {
        return pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          console.log(to)
          return (
            <li key={to}>
              <Link to={to}>{dicionarioAbas[value]}</Link>
              <IoIosArrowForward className='icon-aba'/>
            </li>
          );
        });
      };

    return(

        <div className='breadcrumb'>
            <ol>
                <li>
                    <Link to="/">Home</Link>
                    <IoIosArrowForward className='icon-aba'/>
                </li>
                {renderBreadcrumbItems()}
            </ol>
        </div>
    );
}

export default Abas;