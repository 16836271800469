import NavBarPub from '../../lib/componentes/NavBarPub'
import './MinhaLoja.css'
import { fetchWithAuth } from '../../servicos/api';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState, useCallback } from 'react';
import { FaSearch } from 'react-icons/fa';
import {fetchWithoutAuth , API_URL} from '../../servicos/api'
import { Link } from 'react-router-dom';
import BotaoAzul from '../../lib/componentes/BotaoAzul';
import formatValor from '../../utils/formatValor';
import { IoClose } from "react-icons/io5";

function MinhaLoja() {

    const { seller_id } = useParams();
    const [anuncios, setAnuncios] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [busca, setBusca] = useState('');
    const itemsPerPage = 8;
    console.log(seller_id);

    const fetchAnuncios = useCallback(async () => {
        try {
            const response = await fetchWithoutAuth(`${API_URL}/sellers/${seller_id}/ads/active/public`);
            setAnuncios(response);
        } catch (error) {
            console.error('Error fetching anuncios:', error);
        }
    }, [seller_id]);

    useEffect(() => {
        fetchAnuncios();
    }, [fetchAnuncios]);

    const handleChange = (event) => {
        setBusca(event.target.value);
    };

    const handleSearch = async (event) => {

        event.preventDefault();

        try {
            const userId = localStorage.getItem('id');
            if (!userId) {
                throw new Error('No user ID found');
            }
            const response = await fetchWithAuth(`${API_URL}/sellers/${userId}/ads/active/buscar/${busca}`);
            
            setAnuncios(response);
        } catch (error) {
            console.error('Error fetching anuncios:', error);
        }

    }

    const handleClear = () => {
        setBusca(''); // Limpa o campo de busca
        fetchAnuncios();
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = anuncios.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(anuncios.length / itemsPerPage);

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return(

        <div className="tela" style={{backgroundColor:'#d4d4d4'}}>

            <NavBarPub seller_id={seller_id}/>

            <div className='conteudo-minha-loja' style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/Image Fundo.png')`}}>
                <h1 style={{whiteSpace:'nowrap'}}>
                    <span className='titulo-minhaloja' style={{marginRight:'3%'}}>{anuncios.length}</span>
                    <span className='titulo-minhaloja' style={{fontSize:'130%'}}>Carros Disponíveis</span>
                </h1>
                <form className='search-bar' onSubmit={handleSearch}>
                    <button className='botao-search-icon-minhaloja' type='submit'><FaSearch className='search-icon-minhaloja'/></button>
                    <input type="text" placeholder="Digite a marca ou modelo do carro" className='search-input' value={busca} onChange={handleChange} />
                    <IoClose onClick={handleClear}/>
                </form>
            </div>

            <div style={{display:'flex',alignItems:'flex-start',paddingLeft:'10%'}}>
                <input type="text" placeholder="Ordenar Por" className="input-busca-minhaloja" style={{width:'130px'}} />
            </div>

            <div className='grid-anuncios-minhaloja'>
                {currentItems.map((anuncio, index) => {
                    const mediaUrl = anuncio.media.length > 0 ? anuncio.media[0].media_url : 'https://i.imgur.com/YtB7z9D.jpeg'; // Default image if media is empty
                    const fipe = formatValor(anuncio.fipe_value);
                    const anunciado = formatValor(anuncio.announced_value);
                    return (
                        <div key={index}>

                            <img src={mediaUrl} alt="Capa" className="imagem-anuncio-minhaloja" />

                            <div className='texto-anuncio-minhaloja'
                            style={{marginBottom:'3%',marginTop:'1%'}}>
                            {anuncio.title}
                            </div>

                            <div className='texto-anuncio-minhaloja' 
                            style={{fontSize:'90%',color:'#6a8bea',marginBottom:'2%'}}>
                            <span style={{color:'black'}}>FIPE:</span> R$ {fipe}
                            </div>

                            <div className='texto-anuncio-minhaloja' 
                            style={{fontSize:'105%',marginBottom:'2%'}}>
                            POR: R$ {anunciado}
                            </div>

                            <div className='texto-anuncio-minhaloja' style={{fontSize:'80%',fontWeight:'normal'}}>Rio de Janeiro - RJ</div>

                            <Link to={`/venda/${anuncio.ad_id}`} style={{textDecoration:'none'}}>
                                <BotaoAzul texto={'Fazer Proposta'} estilo={{marginTop:'7.5%'}} />
                            </Link>
                        
                        </div>
                    );
                    })}
            </div>

            {totalPages > 1 && (
                    <div className="pagination-minhaloja">
                        <button onClick={handlePrevPage} disabled={currentPage === 1}>
                            Anterior
                        </button>
                        <span>{currentPage} de {totalPages}</span>
                        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                            Próxima
                        </button>
                    </div>
                )}

        </div>
    )


}

export default MinhaLoja;