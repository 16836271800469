import './MeusAnuncios.css';
import '../../App.css';
import NavBarLogin from '../../lib/componentes/NavBarLogin';
import BarraLateral from '../../lib/componentes/BarraLateral';
import React, { useEffect, useState, useCallback } from 'react';
import { fetchWithAuth , API_URL} from '../../servicos/api';
import { FaSearch } from 'react-icons/fa';
import { IoClose } from "react-icons/io5";
import Abas from '../../lib/componentes/Abas';
import { Link } from 'react-router-dom';
import formatDate from '../../utils/formatDate';

const MeusAnuncios = () => {
    const [anuncios, setAnuncios] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [busca, setBusca] = useState('');
    const itemsPerPage = 6;

    const handleChange = (event) => {
        setBusca(event.target.value);
    };

    const handleSearch = async (event) => {

        event.preventDefault();

        try {
            const userId = localStorage.getItem('id');
            if (!userId) {
                throw new Error('No user ID found');
            }
            const response = await fetchWithAuth(`${API_URL}/sellers/${userId}/ads/active/buscar/${busca}`);
            const anunciosComBids = await Promise.all(response.map(async anuncio => {
                const bidsResponse = await fetchWithAuth(`${API_URL}/bids/count/${anuncio.ad_id}`);
                anuncio.bidsCount = bidsResponse.count;
                return anuncio;
            }));
            setAnuncios(anunciosComBids);
        } catch (error) {
            console.error('Error fetching anuncios:', error);
        }


    }

    const fetchAnuncios = useCallback(async () => {
        try {
            const userId = localStorage.getItem('id');
            if (!userId) {
                throw new Error('No user ID found');
            }
            const response = await fetchWithAuth(`${API_URL}/sellers/${userId}/ads/active`);
            const anunciosComBids = await Promise.all(response.map(async anuncio => {
                const bidsResponse = await fetchWithAuth(`${API_URL}/bids/count/${anuncio.ad_id}`);
                anuncio.bidsCount = bidsResponse.count;
                return anuncio;
            }));
            setAnuncios(anunciosComBids);
        } catch (error) {
            console.error('Error fetching anuncios:', error);
        }
    }, []);

    const linkToPage = (ad_id) => {
        try {
            const message = `http://autoslances.com.br/venda/${ad_id}`;
            navigator.clipboard.writeText(message); // Copy to clipboard
            alert('Link copied to clipboard');
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchAnuncios();
    }, [fetchAnuncios]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = anuncios.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(anuncios.length / itemsPerPage);

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleClear = () => {
        setBusca(''); // Limpa o campo de busca
        fetchAnuncios();
    };


    return (
        <div className="tela">
            <NavBarLogin />
            <Abas />
            <BarraLateral />
            <div className="conteudo">
                <h1 className="titulo-meusanuncios">Meus Anúncios</h1>
                <div className="filtros">
                    <form className="busca" onSubmit={handleSearch}>
                        <div className='busca-container-meusanuncios'>
                            <input type="text" placeholder="Busca..." className="input-busca" value={busca} onChange={handleChange} />
                            <IoClose onClick={handleClear} className='botao-limpar-meusanuncios'/>
                        </div>
                        <button className="botao-busca" type='submit'><FaSearch className='icone-busca' /> Buscar</button>
                    </form>
                    
                    <Link to='criar-anuncio' style={{ textDecoration: 'none', color: 'inherit' }}>
                        <button className="botao-criar-anuncio">
                            Criar Anúncio
                        </button>
                    </Link>
                    
                </div>
                <table className="tabela-anuncios-meus-anuncios">
                    <thead>
                        <tr>
                            <th style={{borderRadius: '6px 0px 0px 0px'}}>Capa</th>
                            <th>Título Anúncio</th>
                            <th>Link Público</th>
                            <th>Criado Em</th>
                            <th>Compradores</th>
                            <th style={{borderRadius: '0px 6px 0px 0px'}}>Carro</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((anuncio, index) => {
                            const mediaUrl = anuncio.media.length > 0 ? anuncio.media[0].media_url : 'https://i.imgur.com/YtB7z9D.jpeg'; // Default image if media is empty
                            const dataAnuncio = formatDate(anuncio.initial_date);
                            return (
                                <tr key={index}>
                                    <td style={{width:'220px'}}><img src={mediaUrl} alt="Capa" className="thumbnail-meus-anuncios" /></td>
                                    <td className='texto-tabela-meusanuncios' style={{textAlign:'left',paddingLeft:'2%',fontWeight:'bold'}}>{anuncio.title}</td>
                                    <td style={{width:'180px'}}><button className='botao-copiarLink-meusanuncios' onClick={() => linkToPage(anuncio.ad_id)}>Copiar Link</button></td>
                                    <td className='texto-tabela-meusanuncios'>{dataAnuncio}</td>
                                    <td className='texto-tabela-meusanuncios' style={{fontSize:'130%'}}>{anuncio.bidsCount}</td>
                                    <td style={{width:'150px'}}><Link to={`/meus-anuncios/detalhes/${anuncio.ad_id}`}
                                     style={{textDecoration:'none',color:'white'}}><button className='botao-ver-anuncio-meusanuncios'>Ver</button>
                                    </Link></td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {totalPages > 1 && (
                    <div className="pagination">
                        <button onClick={handlePrevPage} disabled={currentPage === 1}>
                            Anterior
                        </button>
                        <span>{currentPage} de {totalPages}</span>
                        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                            Próxima
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MeusAnuncios;
