import './Encerrados.css'
import NavBarLogin from '../../lib/componentes/NavBarLogin';
import BarraLateral from '../../lib/componentes/BarraLateral';
import React, { useEffect, useState, useCallback } from 'react';
import { fetchWithAuth } from '../../servicos/api';
import { FaSearch } from 'react-icons/fa';
import Abas from '../../lib/componentes/Abas';
import { MdOutlineCancel } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import formatDate from '../../utils/formatDate';
import formatValor from '../../utils/formatValor';
import {API_URL} from '../../servicos/api';

function Encerrados() {

    const [sales, setSales] = useState([]);
    const [vendidos, setVendidos] = useState(0);
    const [removidos, setRemovidos] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;

    const fetchSales = useCallback(async () => {
        try {
            const userId = localStorage.getItem('id');
            if (!userId) {
                throw new Error('No user ID found');
            }
            const response = await fetchWithAuth(`${API_URL}/sales/${userId}`);
            const salesData = await response;

            const salesDetails = await Promise.all(salesData.map(async sale => {
                const adResponse = await fetchWithAuth(`${API_URL}/ad_livre/${sale.ad_id}`);
                const ad = await adResponse;

                sale.title = ad.title;
                sale.ad_price = formatValor(ad.announced_value);
                sale.initial_date = formatDate(sale.initial_date);
                sale.final_date = formatDate(sale.final_date);
                sale.sale_price = formatValor(sale.sale_price);

                return sale;
            }));

            setSales(salesDetails);

            const vendidosCount = salesDetails.filter(sale => sale.status === 'vendido').length;
            const removidosCount = salesDetails.filter(sale => sale.status === 'removido').length;

            setVendidos(vendidosCount);
            setRemovidos(removidosCount);

        } catch (error) {
            console.error('Error fetching vendas:', error);
        }
    }, []);

    useEffect(() => {
        fetchSales();
    }, [fetchSales]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = sales.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(sales.length / itemsPerPage);

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <div className="tela">
            <NavBarLogin />
            <Abas />
            <BarraLateral />
            <div className="conteudo">
                <h1 className="titulo-encerrados">Anúncios Encerrados</h1>
                <div style={{textAlign:'left',fontWeight:'bold',color:'#484f5a'}}>Pesquisar anúncios</div>
                <div className="filtros">
                    <div className="busca-encerrados">
                        <input type="text" placeholder="Busque pelo título" className="input-busca" />
                        <input type="text" placeholder="Ínicio" className="input-busca" style={{width:'100px'}} />
                        <input type="text" placeholder="Fim" className="input-busca" style={{width:'100px'}}/>
                        <button className="botao-busca"><FaSearch className='icone-busca' /></button>
                        <div className='circulo' style={{marginLeft: 'auto'}}><FaCheck className='circulo-icone'/></div>
                        <div className='circulo-texto' style={{marginRight:'2%'}}>{vendidos}</div>
                        <div className='circulo' style={{backgroundColor:'#e05858'}}><MdOutlineCancel className='circulo-icone'/></div>
                        <div className='circulo-texto'>{removidos}</div>
                    </div>
                </div>
                <table className="tabela-anuncios">
                    <thead>
                        <tr>
                            <th>Título Anúncio</th>
                            <th>Data Início</th>
                            <th>Data Fim</th>
                            <th>Valor Anúncio</th>
                            <th>Ofertas</th>
                            <th>Status</th>
                            <th>Valor Venda</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((venda, index) => {
                            return (
                                <tr key={index}>
                                    <td>{venda.title}</td>
                                    <td>{venda.initial_date}</td>
                                    <td>{venda.final_date}</td>
                                    <td>R$ {venda.ad_price}</td>
                                    <td>{venda.bids}</td>
                                    <td><div className='botao-status-encerrados'>{venda.status}</div></td>
                                    <td>R$ {venda.sale_price}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {totalPages > 1 && (
                    <div className="pagination">
                        <button onClick={handlePrevPage} disabled={currentPage === 1}>
                            Anterior
                        </button>
                        <span>{currentPage} de {totalPages}</span>
                        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                            Próxima
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};


export default Encerrados