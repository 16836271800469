import './AdLivre.css'
import React, { useState } from 'react';
import { addTable } from '../../servicos/api';
import { LuUploadCloud } from "react-icons/lu";
import ImagePreview from '../../lib/componentes/ImagePreview';

function AdLivre() {

    const [formData, setFormData] = useState({

        seller_id:localStorage.getItem('id') || '',
        title: '',
        fipe_value: '',
        announced_value: '',
        details: '',
        source: '',
        media: []

    });

    console.log(formData.seller_id);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleImageChange = (e) => {
        const files = e.target.files; // Captura todos os arquivos selecionados pelo usuário
        const newMedia = [];
    
        Array.from(files).forEach(file => {
            const reader = new FileReader();
    
            reader.onloadend = () => {
                const base64String = reader.result;
                const regex = /^data:image\/[a-zA-Z+]+;base64,/;
                const base64WithoutPrefix = base64String.replace(regex, "");
    
                // Adiciona a nova imagem ao array newMedia
                newMedia.push({ media_url: base64WithoutPrefix, media_type: 'foto' });
    
                // Quando todas as imagens forem processadas, atualiza o estado
                if (newMedia.length === files.length) {
                    setFormData(prevData => ({
                        ...prevData,
                        media: [...prevData.media, ...newMedia]
                    }));
                }
            };
    
            if (file) {
                reader.readAsDataURL(file); // Lê o arquivo como base64
            }
        });
    };
    

    const mediaUrls = formData.media.map(item => item.media_url);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await addTable(formData);
            alert('Tabela adicionada!');
        } catch (error) {
            alert('Erro!');
        }
    };

    return (

        <div>
            <form style={{display:'flex',flexDirection:'column',alignItems:'center'}} onSubmit={handleSubmit}>

                <div className='container-form-group'>
                    <div className='form-group-adLivre'>
                        <label>*Título do Anúncio</label>
                        <input 
                        type="text" 
                        name="title" value={formData.title} 
                        onChange={handleChange} 
                        required 
                        className="form-input-adlivre" 
                        placeholder='Escreva o título aqui' />
                    </div>
                </div>

                <div className='container-form-group' style={{justifyContent:'space-between'}}>

                    <div className='form-group-adLivre' style={{width:'40%'}}>
                        <label>FIPE</label>
                        <input 
                        type="text" 
                        name="fipe_value" 
                        value={formData.fipe_value} 
                        onChange={handleChange} 
                        required 
                        className="form-input-adlivre" 
                        placeholder='Valor em R$' />

                    </div>

                    <div className='form-group-adLivre' style={{width:'40%'}}>

                        <label>*Valor Anunciado</label>
                        <input 
                        type="text" 
                        name="announced_value" 
                        value={formData.announced_value} 
                        onChange={handleChange} 
                        required 
                        className="form-input-adlivre"
                        placeholder='Valor em R$' />
                    </div>

                </div>

                <div className='container-form-group'>

                    <div className='form-group-adLivre'>
                        <label>*Informações</label>
                        <textarea 
                        type="text" 
                        name="details" 
                        value={formData.details} 
                        onChange={handleChange} 
                        required 
                        className="form-input-descricao-adlivre"
                        style={{height:'400px'}}
                        placeholder='COLE AS INFORMAÇÕES DO CARRO AQUI' />
                    </div>

                </div>

                <div className='container-form-group'>
                    <div className='form-group-adLivre'>
                        <label>Quem te passou o Carro Anunciado?</label>
                        <input 
                        type="text" 
                        name="source" 
                        value={formData.source} 
                        onChange={handleChange} 
                        required 
                        className="form-input-adlivre"
                        placeholder='Nome do Contato' />
                    </div>
                </div>

                <div className='container-form-group-foto' style={{height:'200px'}}>
                    <div className='form-group-adLivre-foto' style={{borderRadius:'10px', borderStyle:'dashed'}}>

                    <LuUploadCloud/>
                    <div>Drop files here</div>
                    <div>Supported formats: PNG, JPG</div>
                    <div>OR</div>
                    <input 
                        type="file" 
                        name="imagem"
                        id = "file"
                        onChange={handleImageChange}
                        multiple
                        className="inputfile"/>
                        <label htmlFor="file">Browse Files</label>

                    </div>

                    <div className='form-group-adLivre-foto' style={{padding:'0',borderRadius:'10px'}}>

                        <ImagePreview mediaUrls={mediaUrls} url_type={'base64'}/>

                    </div>
                </div>


                <button type="submit" className="addTable-button">Criar Anúncio</button>
            </form>
        </div>
    );
}

export default AdLivre;