import './CriarAnuncio.css'
import '../../App.css'
import BarraLateral from '../../lib/componentes/BarraLateral';
import NavBar from '../../lib/componentes/NavBar';
import Abas from '../../lib/componentes/Abas';
import RadioButton from '../../lib/componentes/RadioButton';
import AdLivre from './AdLivre';

function CriarAnuncio() {

    const radioButton_criarAnuncio = [
        { label: 'ANÚNCIO LIVRE', value: 'anunciolivre' }
    ];

    return(

        <div className='tela'>

            <NavBar/>
            <Abas/>
            <BarraLateral/>

            <div className='conteudo'>
                <h1 className='titulo-criarAnuncio'>Criar Anúncio de Venda</h1>
                <RadioButton options = {radioButton_criarAnuncio} initialValue={radioButton_criarAnuncio[0].value}/>
                <AdLivre />
            </div>
            

        </div>
    );
}

export default CriarAnuncio;