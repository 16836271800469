import Abas from '../../lib/componentes/Abas';
import BarraLateral from '../../lib/componentes/BarraLateral';
import NavBar from '../../lib/componentes/NavBar';
import './MeuEstoque.css'
import React, { useEffect, useState, useCallback } from 'react';
import { fetchWithAuth } from '../../servicos/api';
import { Link } from 'react-router-dom';
import BotaoToggle from '../../lib/componentes/BotaoToggle';
import FormMeuEstoque from './FormMeuEstoque';
import { CiShare2 } from "react-icons/ci";
import formatDate from '../../utils/formatDate';
import {API_URL} from '../../servicos/api';

function MeuEstoque() {

    const [anuncios, setAnuncios] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [sellerId,setSellerId] = useState(0);
    const itemsPerPage = 6;

    const fetchAnuncios = useCallback(async () => {
        try {
            const userId = localStorage.getItem('id');
            setSellerId(userId);
            if (!userId) {
                throw new Error('No user ID found');
            }
            const response = await fetchWithAuth(`${API_URL}/sellers/${userId}/ads`);
            setAnuncios(response);
        } catch (error) {
            console.error('Error fetching anuncios:', error);
        }
    }, []);

    useEffect(() => {
        fetchAnuncios();
    }, [fetchAnuncios]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = anuncios.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(anuncios.length / itemsPerPage);

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (

        <div className='tela'>

            <NavBar/>
            <Abas/>
            <BarraLateral/>

            <div className='conteudo'>

                <h1 className='titulo-meuEstoque'>Meu Estoque</h1>
                <div style={{display:'flex',justifyContent:'space-between'}}>
                    <h2 className='subtitulo-meuEstoque'>Esta é sua página pública, com seus anúncios ativos,
                    para que seus clientes conheçam o estoque disponível</h2>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <p className='texto-meuEstoque'>Divulgue seu Estoque</p>
                        <div className='texto-meuEstoque'><Link to={`/minha-loja/${sellerId}`}><CiShare2/>Link da sua Página</Link></div>
                    </div>
                </div>

                <FormMeuEstoque/>

                <div style={{display:'block',marginTop:'20px',height:'2vh',backgroundColor:'#002567'}}></div>

                <h1 className='titulo-meuEstoque' style={{marginTop:'20px',fontSize:'25px'}}>Anúncios do Meu Estoque</h1>

                <table className="tabela-anuncios-meuestoque">
                    <thead>
                        <tr>
                            <th style={{borderRadius: '6px 0px 0px 0px'}}>Capa</th>
                            <th>Título Anúncio</th>
                            <th>Criado Em</th>
                            <th>Carro</th>
                            <th style={{borderRadius: '0px 6px 0px 0px'}}>Exibir na Loja</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((anuncio, index) => {
                            const mediaUrl = anuncio.media.length > 0 ? anuncio.media[0].media_url : 'https://i.imgur.com/YtB7z9D.jpeg'; // Default image if media is empty
                            const date = formatDate(anuncio.initial_date);
                            return (
                                <tr key={index}>
                                    <td style={{width:'220px'}}><img src={mediaUrl} alt="Capa" className="thumbnail-meuestoque" /></td>
                                    <td style={{fontWeight:'bold'}}>{anuncio.title}</td>
                                    <td>{date}</td>
                                    <td><Link to={`/meus-anuncios/detalhes/${anuncio.ad_id}`}
                                     style={{textDecoration:'none',color:'white'}}><button className='botao-ver-anuncio-meusanuncios'>Ver</button></Link></td>
                                    <td style={{width:'150px'}}><BotaoToggle status={anuncio.status} ad_id={anuncio.ad_id}/></td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {totalPages > 1 && (
                    <div className="pagination">
                        <button onClick={handlePrevPage} disabled={currentPage === 1}>
                            Anterior
                        </button>
                        <span>{currentPage} de {totalPages}</span>
                        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                            Próxima
                        </button>
                    </div>
                )}
            </div>
        </div>
    
    );


}

export default MeuEstoque;