import './BarraLateral.css';
import { Link, useLocation, Navigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../../servicos/AuthContext';
import { FaCar, FaFlagCheckered } from "react-icons/fa";
import { CiBoxes } from "react-icons/ci";
import { RiContactsLine } from "react-icons/ri";
import { GiSpeedometer, GiCarKey } from "react-icons/gi";
import { HiOutlinePresentationChartLine } from "react-icons/hi";
import { FiTarget } from "react-icons/fi";
import { IoMdStopwatch } from "react-icons/io";

function BarraLateral() {
    const { auth } = useContext(AuthContext);
    const location = useLocation();

    const getActiveClass = (path) => location.pathname === path ? "active" : "";

    if (!auth.isAuthenticated) {
        return <Navigate to="/" />;
    }

    return (
        <div className='barra'>
            <ul>
                <li className={getActiveClass('/meus-anuncios')}>
                    <Link to='/meus-anuncios' style={{ textDecoration: 'none', color: 'inherit' }}>
                        <FaCar className='icon'/> Anúncios
                    </Link>
                </li>
                <li className={getActiveClass('/meus-anuncios/criar-anuncio')}>
                    <Link to='/meus-anuncios/criar-anuncio' className='subitem' style={{ textDecoration: 'none', color: 'inherit' }}>
                        <GiCarKey className='icon'/> Criar Novo
                    </Link>
                </li>
                <li className={getActiveClass('/encerrados')}>
                    <Link to='/encerrados' className='subitem' style={{ textDecoration: 'none', color: 'inherit' }}>
                        <FaFlagCheckered className='icon'/> Encerrados
                    </Link>
                </li>
                <li className={getActiveClass('/meu-estoque')}>
                    <Link to='/meu-estoque' style={{ textDecoration: 'none', color: 'inherit' }}>
                        <CiBoxes className='icon'/> Meu Estoque
                    </Link>
                </li>
                <li>
                    <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                        <RiContactsLine className='icon'/> Contatos
                    </Link>
                </li>
                <li>
                    <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                        <GiSpeedometer className='icon'/> Painéis
                    </Link>
                </li>
                <li className='subitem'>
                    <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                        <HiOutlinePresentationChartLine className='icon'/> Negócios
                    </Link>
                </li>
                <li className='subitem'>
                    <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                        <FiTarget className='icon'/> Mercado
                    </Link>
                </li>
                <li className='subitem'>
                    <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                        <IoMdStopwatch className='icon'/> Produtividade
                    </Link>
                </li>
            </ul>
        </div>
    );
}

export default BarraLateral;
